<template>
  <div class="p-5">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="row" v-if="gettDashboardComponentDataObj">
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Draft Police check'"
              :value="gettDashboardComponentDataObj.totalDraftPC"
              :bgColor="'bg-secondary text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'New Police check'"
              :value="gettDashboardComponentDataObj.totalNewPC"
              :bgColor="'bg-primary text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'More Information Required'"
              :value="gettDashboardComponentDataObj.totalMIRPC"
              :bgColor="'bg-warning text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Waiting for Police Check Review'"
              :value="gettDashboardComponentDataObj.totalWFPRPC"
              :bgColor="'bg-success text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Completed'"
              :value="gettDashboardComponentDataObj.totalCompletedPC"
              :bgColor="'bg-info text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Canceled'"
              :value="gettDashboardComponentDataObj.totalCanceledPC"
              :bgColor="'bg-black text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import WDInfoBoxCom from "../../components/wd/WDInfoBoxCom.vue";
export default {
  components: {
    WDInfoBoxCom,
  },
  data: function () {
    return {
      loginUserObj: {},
    };
  },
  computed: {
    ...mapGetters(["gettDashboardData", "gettDashboardComponentDataObj"]),
  },
  methods: {
    ...mapActions(["fetchDashboardData"]),
  },

  created() {
    // Progress bar
    this.$Progress.start();
    this.loginUserObj = authService.getUserFromToken();
    this.fetchDashboardData(this.loginUserObj.parent_id);
  },
};
</script>
