<template>
  <div class="info-box">
    <span class="info-box-icon elevation-1" :class="bgColor"
      ><i :class="fontClass"></i
    ></span>
    <div class="info-box-content">
      <span class="info-box-text">{{ title }}</span>
      <span class="info-box-number">
        {{ value }}
        <span v-if="url">
          <a :href="url" class="small-box-footer">
            <i class="fas fa-arrow-circle-right"></i
          ></a>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "InfoBoxCom",
  props: ["title", "value", "bgColor", "fontClass", "url"],
  data() {
    return {};
  },
};
</script>
